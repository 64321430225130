import { lazy } from 'react';
import { useRoutes } from 'react-router-dom';

// routes
import Loadable from 'ui-component/Loadable';
import LoginRoutes from './LoginRoutes';

const AuthRegister = Loadable(lazy(() => import('views/pages/authentication/authentication3/Register3')));
// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    return useRoutes([{ path: '/', element: <AuthRegister /> }, 
    // {
    //     path: ':ref',
    //     element: (
    //         <AuthRegister />
    //     )
    // },
    LoginRoutes
    ]);
}
