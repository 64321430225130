import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';

const AuthRegister = Loadable(lazy(() => import('views/pages/authentication/authentication3/Register3')));

// ==============================|| AUTH ROUTING ||============================== //
const LoginRoutes = {
    path: '/referralCode',
    element: (<AuthRegister />),
    children: [
        {
            path: ':ref',
            element: <AuthRegister />
        }
    ]
};

export default LoginRoutes;
